<template>
  <Calendar
    v-model="value"
    selectionMode="range"
    dateFormat="dd/mm/yy"
    @date-select="mergeDate()"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      value: null,
    }
  },
  methods: {
    mergeDate() {
      if (this.value[1] !== null) {
        let dates = []
        this.value.forEach((el) => {
          dates.push(
            el.getFullYear() + '-' + (el.getMonth() + 1) + '-' + el.getDate()
          )
        })
        this.$emit('update:modelValue', dates.join(','))
        this.$emit('select')
      }
    },
  },
}
</script>
